.page-enter {
    opacity: 0;
}

.page-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.container {
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-container {
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'flex-start';
    align-items: 'center';
    height: 100%;
}

.text-container h1 {
    font-size: 4rem;
    font-family: Roboto Mono;
    color: #063970;
    margin: 50px;
}

.text-container h2 {
    font-size: 1.5rem;
    font-family: Roboto Mono;
    color: black;
    margin-bottom: 50px;
}

.text-container h3 {
    font-size: 1.2rem;
    font-family: Roboto Mono;
    color: gray;
    margin: 10px;
}

.text-container ul {
    list-style-type: none;
}

.images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.images-container img {
    max-width: 100%;
    height: auto;
    flex: 1 1 calc(33.33% - 20px);
}

@media (max-width: 768px) {
    .images-container img {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .images-container img {
        flex: 1 1 calc(100% - 20px);
    }
}